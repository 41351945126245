body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  width: 120px;
  height: 31px;
  background: #949494;
  background-image: url("https://logos-world.net/wp-content/uploads/2020/09/Google-Logo-700x394.png");
  margin: 16px 24px 16px 0;
  float: left;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

/* FullscreenSpinner.css */
.fullscreen-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.order-modal {
  width: 100%;
  transform-origin: 85px 43px;
  overflow: hidden;
  height: -webkit-fill-available;
}

@media (max-width: 768px) {
  .ant-layout-sider {
    display: none;
  }
}

@media (min-width: 769px) {
  .ant-layout-sider {
    display: block;
  }
}

.dark-placeholder::placeholder {
  color: #4a4a4a; /* Set your preferred darker color here */
  opacity: 1; /* Ensure the opacity is fully opaque */
}

@font-face {
  font-family: "Diazo MVB Cond Bold";
  src: url("https://admin.echonetworks.io/fonts/fonnts.com-Diazo_MVB_Cond_Bold.otf");
}

@font-face {
  font-family: "BioSans Regular";
  src: url("https://admin.echonetworks.io/fonts/Flat-it - BioSans-Regular.otf");
}

/*
 * Override heading fonts
 */
h1, h2, h3, h4, h5, h6, .ant-list-header span {
  font-family: "Diazo MVB Cond Bold", sans-serif !important;
}